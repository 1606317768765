/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import Rails from "@rails/ujs";
Rails.start();

import "bootstrap/dist/js/bootstrap";

import "javascripts/asset-image";
import "javascripts/autosize";
import "javascripts/clipboard-copy";
import "javascripts/collapse-hotel-parts-fortravel-review";
import "javascripts/shared";
import "javascripts/twitter_widget";

import "javascripts/webpage/aggregated_hotels-show";
import "javascripts/webpage/aos";
import "javascripts/webpage/bulk-clips";
import "javascripts/webpage/calendar";
import "javascripts/webpage/clips";
import "javascripts/webpage/common";
import "javascripts/webpage/create-user";
import "javascripts/webpage/destination";
import "javascripts/webpage/dropdown-list";
import "javascripts/webpage/fortravel-secondary-reviews";
import "javascripts/webpage/grecaptcha";
import "javascripts/webpage/hotels-index";
import "javascripts/webpage/inquiries";
import "javascripts/webpage/instagram_no_image";
import "javascripts/webpage/lazyload";
import "javascripts/webpage/link";
import "javascripts/webpage/loader-hotels";
import "javascripts/webpage/loader-restaurants";
import "javascripts/webpage/loader-room-plans";
import "javascripts/webpage/loader-vacancy-pickup-hotels";
import "javascripts/webpage/map-image";
import "javascripts/webpage/modal-lazyload";
import "javascripts/webpage/navigation";
import "javascripts/webpage/onclick";
import "javascripts/webpage/persons";
import "javascripts/webpage/presses";
import "javascripts/webpage/reset-form";
import "javascripts/webpage/rich_content";
import "javascripts/webpage/searchbox";
import "javascripts/webpage/sentry";
import "javascripts/webpage/smooth-scroll";
import "javascripts/webpage/specials";
import "javascripts/webpage/spot-map";
// NOTE: #9830 脆弱性指摘により機能停止。もし再開する場合は要再検討
// import "javascripts/webpage/surveys";
import "javascripts/webpage/swipe-for-windows";
import "javascripts/webpage/tab";
import "javascripts/webpage/trip-lists";
import "javascripts/webpage/users";
import "javascripts/webpage/user-created-success-modal";

import "javascripts/webpage/sp/aggregated_hotels-show";
// NOTE: #10577 ビルボードスティッキーを表示しない期間はコメントアウト
import "javascripts/webpage/sp/billboard-sticky";
import "javascripts/webpage/sp/header-scroll";
import "javascripts/webpage/sp/hotels-index";
import "javascripts/webpage/sp/ios-pwa";
import "javascripts/webpage/sp/pages-top";
import "javascripts/webpage/sp/presses-show";
import "javascripts/webpage/sp/spottable";
